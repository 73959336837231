.busNumber{
    background-color:purple;  
    border:2px solid;
}

.bus1{
    background-color: red;
}

.bus2{
    background-color: gold;
    color:black;
}

.bus3{
    background-color: green;
}

.bus4{
    background-color: blue;
}

.bus112{
    background-color: orange;
    color:black;
}



