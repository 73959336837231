#singleStopTimetableContainer{
    display:flex;
    justify-content: space-evenly;
    align-items:flex-start;
    width:100%;
  }

  #lastUpdateText{
    position:absolute;
    bottom:0;
  }



  .ttable,tbody{
        border-collapse:separate;
        border-spacing:0 50px;
     }


  .logoImg{
    position:absolute;
    top: 0px;
    left: 0px;
  }

  #singleHeader{
    margin-top: 2vh;
    position:absolute;
    left:0;
    top:0;
  }

  #singleStoptrafficViewer{
    display:flex;
    justify-content: space-around;
    align-items:center;
    width:100vw;
    height:100vh;
    font-size:2vw;
  }

  #stopNameTitle{
    text-transform: capitalize;
  }

  @media screen and (max-width: 576px)  {

    #singleHeader{
      position:relative;
    }

    #singleStoptrafficViewer{
      display:flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: space-evenly;
      width:100vw;
      min-height:100vh;
      
    }

    #singleStopTimetableContainer{
      margin:1rem 1rem;
      padding-left:1rem;
      padding-right:1rem;
      display:flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items:center;
      width:100%;
    }
  }