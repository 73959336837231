
.busNumber{
    color:white;
    background-color: red;
    text-align: center;
  }

.directionText{
    text-align:center;
    padding-left:0;
    margin-left:0;
}

table, tr, td{
  border:none;
      border-collapse:separate;
      border-spacing:0 15px;
   }
  
  td{
    padding:1vw 2vw;
  }

  tr:nth-child(odd) {
    background-color: var(--table-stripe-colour);
    background-color:white
  }

  .directionText{
    font-weight: bold;
  }

  .directionText.tr:nth-child(even) {

  
  }

  .timeCell{
    text-align: center;
  }

  .noBus{
    background-color: var(--main-bg-color);
    text-align:left;
    padding-left:0;
    margin-left:0;
  }


.tableTitleRow th{
  text-align: center;
}

@media screen and (min-width: 576px) and (max-width: 768px)  {
 
    td{
      padding:1rem 0.5rem;
    }
  }
