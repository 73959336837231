:root {
  --main-bg-color: black;
  --main-text-color: white;
  --table-stripe-colour:#202020;
}

*,body,html{
  margin:0;
  padding:0;
  box-sizing:border-box;
  font-family: 'Roboto', sans-serif;
}

h1{
  font-size:3vw;
}

html{
  height:100vh;
  width:100vw;
  overflow:hidden;
}



body{
  height:100vh;
  width:100vw;
  background-color:var(--main-bg-color);
  color:var(--main-text-color);
  font-size:1vw;
  overflow:hidden;
}

div.container-fluid{
  padding-left: 0;
  padding-right:0;
}

#errorContainer{
  display:flex;
  justify-content: center;
  align-items: center;
  height:100vh;
  width:100vw;

}

.errorMessage{

}

#trafficViewer{
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  justify-content: center;
  width:100vw;
  height:100vh;
  overflow-x: hidden;
}

header{
  align-items:center;
  display:flex;
  justify-content:center;
  width:100%;
  justify-self:first baseline;
  align-self:start;
}

h2{
  margin-bottom: 1vw;
}

#busLogo{
  font-size:2vw;
  padding:1vw;
}

#timetableContainer{
  display:flex;
  justify-content: space-evenly;
  align-items:flex-start;
  width:100%;

}

.timetable{
  margin-bottom: 1vw;
}

#lastUpdateText{
  margin:0;
  padding:0;
  font-size: 0.5vw;
 width:100%;
 text-align: center;
}



@media screen and (max-width: 576px)  {
  #timetableContainer{
    display:flex;
    flex-direction: column;
    justify-content: stretch;
    align-items:stretch;
    margin-top:2rem;
    width:100%;
    height:100%;
    padding:1rem;
  }

  table{
    width:100%;
  }

  h2{
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

