#errorContainer{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:100vh;
    width:100vw;
  }
  
  .errorMessage{
  
  }
  

#busIcon{
    display:block;
    color:red;
    font-size: 10vh;
    animation: pulse 2s infinite;
    margin:5vh;
}



@keyframes pulse {
	0% {
		transform: scale(0.95);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.95);
	}
}

/* The typing effect */
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }